import { Observable } from 'rxjs';
import {
  DashboardGetQueryParamsDto,
  ResponseDashboardDto,
} from '../dtos/dashboard.dto';

export abstract class DashboardRepository {
  abstract get(
    params: DashboardGetQueryParamsDto
  ): Observable<ResponseDashboardDto>;
}
